import { useContext, useState, createContext } from "react";

const ModalContext = createContext();
const ModalUpdateContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

export function useModalUpdate() {
  return useContext(ModalUpdateContext);
}

export function ModalProvider({ children }) {
  const [modalIsOpen, setOpen] = useState(false);

  const toggleModal = (nextChecked) => {
    setOpen(nextChecked);
  };

  return (
    <ModalContext.Provider value={modalIsOpen}>
      <ModalUpdateContext.Provider value={toggleModal}>
        {children}
      </ModalUpdateContext.Provider>
    </ModalContext.Provider>
  );
}
