import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import TimerAnimation from "../animations/TimerAnimation";

const CurrentTeamNameStyle = style((theme) => ({
  fontFamily: "Neucha",
  fontSize: "48px",
  color: theme.activePlayer,
}));

export const GuessingPlayerActiveTimer = (props) => {
  const currentTeamNameStyle = useThemedStyle(CurrentTeamNameStyle);

  return (
    <>
      <CountdownCircleTimer
        isPlaying
        duration={props.state.countdown}
        colors={[
          ["#395B50", 0.25],
          ["#05B560", 0.25],
          ["#DD6E42", 0.25],
          ["#820933", 0.25],
        ]}
        strokeWidth={18}
      >
        {TimerAnimation}
      </CountdownCircleTimer>{" "}
      <p className={currentTeamNameStyle}>
        {props.state.explainingPlayer.username}
        {"\u00A0"} & {"\u00A0"}
        {props.state.guessingPlayer.username}
      </p>
    </>
  );
};
