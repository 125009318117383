import React from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import TitleDots from "../titles/TitleDots";

const floatingAnimation = keyframes(() => ({
  "0%": {
    transform: "translatey(0px)",
  },
  "50%": {
    transform: "translatey(30px)",
  },
  "100%": {
    transform: "translatey(0px)",
  },
}));

const maxWidth = 700;

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  marginTop: "80px",
}));

const RoomCodeStyle = style(() => ({
  borderRadius: "10px",
  border: "4px solid #EE9746",
  width: "170px",
  height: "70px",
  lineHeight: "0px",
  textAlign: "center",
  color: "#EE9746",
  letterSpacing: "0.14em",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bolder",
  fontSize: "36px",
  marginTop: "10px",
}));

const PlayerListStyle = style(() => ({
  marginTop: "0px",
  marginBottom: "30px",
  maxWidth: "900px",
  fontFamily: "Neucha",
  fontSize: "26px",
  display: "flex",
  [`@media (max-width: ${maxWidth}px)`]: {
    marginTop: "40px",
    fontSize: "18px",
    maxWidth: "460px",
  },
}));

const PlayerUnorderedListStyle = style(() => ({
  listStyle: "none",
  display: "flex",
  flex: "1 0 17%",
  flexWrap: "wrap",
  gap: "80px",
  [`@media (max-width: ${maxWidth}px)`]: {
    gap: "40px",
  },
}));

const ActivePlayerStyle = style((theme, $) => ({
  color: theme.activePlayer,
  transform: "translatey(0px)",
  animation: $(floatingAnimation) + " 6s ease-in-out infinite",
}));

const InactivePlayerStyle = style((theme) => ({
  color: theme.inactivePlayer,
}));

const ButtonPrimaryStyle = style((theme) => ({
  backgroundColor: theme.buttonPrimary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "40px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "40px",
}));

const ButtonSecondaryStyle = style((theme) => ({
  backgroundColor: theme.buttonDelete,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "10px",
}));

function WaitingRoom(props) {
  const rootStyle = useThemedStyle(RootStyle);
  const roomCodeStyle = useThemedStyle(RoomCodeStyle);
  const playerListStyle = useThemedStyle(PlayerListStyle);
  const playerUnorderedListStyle = useThemedStyle(PlayerUnorderedListStyle);
  const activePlayerStyle = useThemedStyle(ActivePlayerStyle);
  const inactivePlayerStyle = useThemedStyle(InactivePlayerStyle);
  const buttonPrimaryStyle = useThemedStyle(ButtonPrimaryStyle);
  const buttonSecondaryStyle = useThemedStyle(ButtonSecondaryStyle);

  let username = localStorage.getItem("username");
  let roomCode = window.localStorage.getItem("roomCode");

  const onSubmit = () => {
    props.submitWaitingRoom(username);
  };

  const onSubmitEndGame = () => {
    props.submitEndGame(username);
  };

  return (
    <>
      <TitleDots>waiting for players</TitleDots>

      <div className={rootStyle}>
        <div className={roomCodeStyle}>
          <p>{roomCode}</p>
        </div>

        <div className={playerListStyle}>
          <ul className={playerUnorderedListStyle}>
            {props.state.players.map(function (player, idx) {
              if (player.isActive) {
                return (
                  <li key={idx}>
                    <div className={activePlayerStyle}>
                      <span>{player.username}</span>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li key={idx}>
                    <span className={inactivePlayerStyle}>
                      {player.username}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>

        {username === props.state.admin.username ? (
          <>
            <button className={buttonPrimaryStyle} onClick={onSubmit}>
              EVERYBODY'S IN!
            </button>
            <button className={buttonSecondaryStyle} onClick={onSubmitEndGame}>
              DELETE ROOM
            </button>
          </>
        ) : null}
      </div>
    </>
  );
}
export default WaitingRoom;
