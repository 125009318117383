import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  marginTop: "60px",
}));

const FormStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
}));

const ButtonPrimaryStyle = style((theme) => ({
  backgroundColor: theme.buttonPrimary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "40px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "60px",
}));

const toInputUppercase = (e) => {
  e.target.value = ("" + e.target.value).toUpperCase();
};

const JoinGameForm = (props) => {
  const rootStyle = useThemedStyle(RootStyle);
  const formStyle = useThemedStyle(FormStyle);
  const buttonPrimaryStyle = useThemedStyle(ButtonPrimaryStyle);
  const validate = Yup.object({
    username: Yup.string()
      .max(15, "must be 15 characters or less")
      .required("required"),
    roomCode: Yup.string().min(4, "must be 4 characters").required("required"),
  });
  return (
    <Formik
      initialValues={{
        username: "",
        roomCode: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        props.joinRoom({
          username: values.username,
          roomCode: values.roomCode,
        });
      }}
    >
      {() => (
        <div className={rootStyle}>
          <Form className={formStyle}>
            <TextField
              label='name'
              name='username'
              type='text'
              placeholder='ENTER YOUR NAME'
            />
            <TextField
              label='room code'
              name='roomCode'
              type='text'
              maxLength='4'
              placeholder='ENTER 4-LETTER CODE'
              onInput={toInputUppercase}
            />

            <button className={buttonPrimaryStyle} type='submit'>
              PLAY
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};
export default JoinGameForm;
