import React from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";

const myAnimation = keyframes(() => ({
  from: { opacity: 1 },
  to: { opacity: 0 },
}));

const RootStyle = style(() => ({
  display: "flex",
  alignItems: "baseline",
}));

const HeadingStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "4vmax",
  textAlign: "center",
  letterSpacing: "0.16em",
  color: theme.color,
  width: "max-content",
  [`@media (max-width: ${520}px)`]: {
    fontSize: "3.5vmax",
  },
}));

const DotStyle = style((theme, $) => ({
  height: "8px",
  width: "8px",
  borderRadius: "50%",
  backgroundColor: theme.color,
  display: "inline-block",
  margin: ".2rem",
  animation: $(myAnimation) + " 1.4s infinite both",
  animationDelay: "0s",
  "&:nth-child(2)": { animationDelay: "0" },
  "&:nth-child(3)": { animationDelay: "-4s" },
  "&:nth-child(4)": { animationDelay: "-1s" },
}));

const TitleDots = ({ children }) => {
  const rootStyle = useThemedStyle(RootStyle);
  const headingStyle = useThemedStyle(HeadingStyle);
  const dotStyle = useThemedStyle(DotStyle);

  return (
    <>
      <div className={rootStyle}>
        <h1 className={headingStyle}>{children}</h1>
        <div className={dotStyle}></div>
        <div className={dotStyle}></div>
        <div className={dotStyle}></div>
      </div>
    </>
  );
};

export default TitleDots;
