import React from "react";
import { motion } from "framer-motion";

const WordGuessedAnimation = () => {
  return (
    <motion.h1
      animate={{
        rotateY: 360,
        color: "green",
        fontFamily: "Fira Sans",
        fontSize: "48px",
        textAlign: "center",
      }}
    >
      +1
    </motion.h1>
  );
};
export default WordGuessedAnimation;
