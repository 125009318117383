import React from "react";
import { ExplainingPlayerActiveTimer } from "./ExplainingPlayerActiveTimer";
import { ExplainingPlayerInactiveTimer } from "./ExplainingPlayerInactiveTimer";

export const ExplainingPlayerContent = (props) => {
  return !props.state.countdown ? (
    <ExplainingPlayerInactiveTimer state={props.state} />
  ) : (
    <ExplainingPlayerActiveTimer
      state={props.state}
      nextWord={props.nextWord}
      previousWord={props.previousWord}
      skipWord={props.skipWord}
    />
  );
};
