import React from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const pulseAnimation = keyframes(() => ({
  "0%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
}));
const CurrentTeamNameWaitingPlayerStyle = style((theme) => ({
  fontFamily: "Neucha",
  fontSize: "48px",
  marginTop: "50px",
  color: theme.activePlayer,
}));

const SpanNextTeamStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontWeight: "bold",
  letterSpacing: "0.16em",
  fontSize: "24px",
  color: theme.color,
}));

const NextTeamNameWaitingPlayerStyle = style(() => ({
  fontFamily: "Neucha",
  fontSize: "27px",
  color: "#BABABA",
  marginTop: "40px",
}));

const SpanStartTimerStyle = style((theme, $) => ({
  backgroundColor: "transparent",
  color: theme.spanNextTeam,
  fontSize: "12px",
  border: 0,
  fontFamily: "Fira Sans",
  fontWeight: "bold",
  animation: $(pulseAnimation) + " 4s infinite both",
}));

export const WaitingPlayerInactiveTimer = (props) => {
  const currentTeamNameWaitingPlayerStyle = useThemedStyle(
    CurrentTeamNameWaitingPlayerStyle
  );
  const nextTeamNameWaitingPlayerStyle = useThemedStyle(
    NextTeamNameWaitingPlayerStyle
  );
  const spanNextTeamStyle = useThemedStyle(SpanNextTeamStyle);
  const spanStartTimerStyle = useThemedStyle(SpanStartTimerStyle);

  return (
    <>
      <CountdownCircleTimer
        isPlaying={false}
        duration={props.state.countdown}
        colors={[["#C2D1CB", 1]]}
        strokeWidth={18}
      >
        <span className={spanStartTimerStyle}>
          {props.state.guessingPlayer.username}
          <br /> should start the timer!
        </span>
      </CountdownCircleTimer>{" "}
      <span className={currentTeamNameWaitingPlayerStyle}>
        {props.state.explainingPlayer.username}
        {"\u00A0"} & {"\u00A0"}
        {props.state.guessingPlayer.username}
      </span>
      <span className={nextTeamNameWaitingPlayerStyle}>
        <span className={spanNextTeamStyle}>next team</span>
        <br />
        {props.state.nextTeam.name}
      </span>
    </>
  );
};
