import React, { useContext, useState, useEffect, useCallback } from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { DarkMode } from "themed-jss/dark-mode";
import { ToastContainer, toast } from "react-toastify";
import "../styles/toastify.css";
import darkModeIcon from "../../assets/darkModeIcon.svg";
import image from "../../assets/info.svg";
import { SocketContext } from "../../context/socket";
import NoConnection from "./NoConnection";
import Room from "../game/Room";
import CreateGame from "./CreateGame";
import JoinGame from "./JoinGame";
import particlesConfigDots from "../../config/particlesConfigDots";
import { SkipsProvider } from "../../context/SkipsContext";
import { useModal, useModalUpdate } from "../../context/ModalContext";
import Particles from "react-tsparticles";
import RulesModal from "./resources/RulesModal";

const RootStyle = style(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "0 0 0 0",
}));

const ImageHelpStyle = style((theme) => ({
  width: "33px",
  filter: theme.filter,
}));

const DarkModeIconStyle = style((theme) => ({
  width: "30px",
  filter: theme.filter,
}));

const FooterStyle = style(() => ({
  marginTop: "20px",
  display: "flex",
  background: "transparent",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "10px",

  [`@media (max-width: ${570}px)`]: {
    justifyContent: "center",
    padding: "0 0 30px 0",
  },
}));

const screens = {
  NoConnection: "NoConnection",
  JoinGame: "JoinGame",
  CreateGame: "CreateGame",
  Room: "Room",
};

const Home = () => {
  const rootStyle = useThemedStyle(RootStyle);
  const darkModeIconStyle = useThemedStyle(DarkModeIconStyle);
  const footerStyle = useThemedStyle(FooterStyle);
  const socket = useContext(SocketContext);
  const [screen, setScreen] = useState(screens.NoConnection);
  const [gameState, setGameState] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const modalIsOpen = useModal();
  const imageHelpStyle = useThemedStyle(ImageHelpStyle);
  const setOpen = useModalUpdate();

  const handleConnection = useCallback(() => {
    setScreen(screens.JoinGame);
    //try to join when refresh happens
    let username = window.localStorage.getItem("username");
    let roomCode = window.localStorage.getItem("roomCode");
    if (username && roomCode) {
      socket.emit("localStorageReconnect", {
        username: username,
        roomCode: roomCode,
      });
    } // eslint-disable-next-line
  }, []);

  const handleError = useCallback(() => {
    setScreen(screens.NoConnection);
  }, []);

  const handleReconnect = useCallback(() => {
    setScreen(screens.NoConnection);
  }, []);

  const handleLocalStorage = useCallback((data) => {
    window.localStorage.setItem("username", data.username);
    window.localStorage.setItem("roomCode", data.roomCode);
  }, []);

  const handleUpdateState = useCallback((newGameState) => {
    setGameState(newGameState);
    if (newGameState.stage != null) {
      setScreen(screens.Room);
    } else {
      setScreen(screens.JoinGame);
    }
  }, []);

  const handleExistingUsernameInRoom = useCallback(() => {
    toast.info("username existing in room", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const handleWrongRoomCode = useCallback(() => {
    toast.info("wrong room code", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  useEffect(() => {
    // as soon as the component is mounted, do the following tasks:

    socket.on("connection", handleConnection);
    socket.on("error", handleError);
    socket.on("reconnect_attempt", handleReconnect);
    socket.on("localStorageSet", handleLocalStorage);
    socket.on("updateState", handleUpdateState);
    socket.on("wrongRoomCode", handleWrongRoomCode);
    socket.on("existingUsernameInRoom", handleExistingUsernameInRoom);

    return () => {
      socket.off("connection", handleConnection);
      socket.off("error", handleError);
      socket.off("reconnect_attempt", handleReconnect);
      socket.off("localStorageSet", handleLocalStorage);
      socket.off("updateState", handleUpdateState);
      socket.off("wrongRoomCode", handleWrongRoomCode);
      socket.off("existingUsernameInRoom", handleExistingUsernameInRoom);
    };
  }, [
    socket,
    handleConnection,
    handleError,
    handleLocalStorage,
    handleUpdateState,
    handleReconnect,
    handleWrongRoomCode,
    handleExistingUsernameInRoom,
  ]);

  const joinRoomHandler = (data) => {
    let username = window.localStorage.getItem("username");
    let roomCode = window.localStorage.getItem("roomCode");
    if (username === data.username && roomCode === data.roomCode) {
      socket.emit("localStorageReconnect", {
        username: data.username,
        roomCode: data.roomCode,
      });
    } else {
      socket.emit("joinRoom", {
        username: data.username,
        roomCode: data.roomCode,
      });
    }
  };

  const createGameHandler = () => {
    setScreen(screens.CreateGame);
  };

  const goBackHandler = () => {
    setScreen(screens.JoinGame);
  };

  const createRoomHandler = (data) => {
    socket.emit(
      "createRoom",
      {
        username: data.username,
        nbWords: data.nbWords,
        timer: data.timer,
        nbSkips: data.nbSkips,
        checkedSkips: data.checkedSkips,
        wordpackOptions: data.wordpackOptions,
      },
      function (response) {
        if (response !== true) {
          setScreen(screens.JoinGame);
        }
      }
    );
  };

  const switchScreens = () => {
    switch (screen) {
      case "NoConnection":
        return <NoConnection />;
      case "JoinGame":
        return (
          <JoinGame joinRoom={joinRoomHandler} createGame={createGameHandler} />
        );

      case "CreateGame":
        return (
          <CreateGame createRoom={createRoomHandler} goBack={goBackHandler} />
        );

      case "Room":
        return <Room gameState={gameState} />;

      default:
        return <p>error</p>;
    }
  };

  return (
    <SocketContext.Provider value={socket}>
      <SkipsProvider>
        <ToastContainer />
        <Particles className='particles' params={particlesConfigDots} />
        <div className={rootStyle}>
          {switchScreens()}
          <div className={footerStyle}>
            {modalIsOpen ? (
              <RulesModal />
            ) : (
              <>
                <input
                  type='image'
                  src={image}
                  alt='img'
                  onClick={setOpen}
                  className={imageHelpStyle}
                />
                <input
                  type='image'
                  src={darkModeIcon}
                  alt='img'
                  onClick={() => {
                    DarkMode.toggle();
                    setDarkMode(!darkMode);
                  }}
                  className={darkModeIconStyle}
                />
              </>
            )}
          </div>
        </div>
      </SkipsProvider>
    </SocketContext.Provider>
  );
};
export default Home;
