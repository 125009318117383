import React from "react";
import Title from "../titles/Title";
import CreateGameForm from "./resources/CreateGameForm";

function CreateGame(props) {
  return (
    <>
      <Title>create room</Title>
      <CreateGameForm createRoom={props.createRoom} goBack={props.goBack} />
    </>
  );
}
export default CreateGame;
