import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { SocketContext, socket } from "./context/socket";
import Home from "./components/home/Home";
import { theme } from "themed-jss";
import { DarkMode } from "themed-jss/dark-mode";
import { Themed } from "themed-jss/react";
import { GlobalStyles } from "./styles/GlobalStyles";
import { ModalProvider } from "./context/ModalContext";

const myTheme = theme(
  {
    //light mode properties
    primary: "#00917c",
    background: "white",
    color: "black",
    buttonPrimary: "#2ECF95",
    buttonSecondary: "#3E97E9",
    buttonTertiary: "#FFD32D",
    buttonDelete: "#F17D63",
    switchButton: "#EE9746",
    activePlayer: "#395B50",
    inactivePlayer: "#E0E0E0",
    placeholder: "#fff",
    spanNextTeam: "#C2D1CB",
    border: "#C2C2C2",
    filter: "black",
  },
  {
    //dark mode properties
    background: "#041C32", //"#04293A", #11324D
    color: "white",
    buttonPrimary: "#21E6C1",
    buttonSecondary: "#1597BB", //"#278EA5"
    buttonTertiary: "#FFD369", //"#FFD717"
    buttonDelete: "#ECB365",
    switchButton: "#ECB365",
    activePlayer: "#03C4A1",
    inactivePlayer: "#182b40",
    placeholder: "#182b40",
    spanNextTeam: "#fff",
    border: "red",
    filter:
      "invert(100%) sepia(0%) saturate(7410%) hue-rotate(114deg) brightness(108%) contrast(99%)",
  }
);

myTheme.add(GlobalStyles);

DarkMode.initialize();

ReactDOM.render(
  <Themed theme={myTheme}>
    <SocketContext.Provider value={socket}>
      <ModalProvider>
        <Home />
      </ModalProvider>
    </SocketContext.Provider>
  </Themed>,
  document.getElementById("root")
);
