import React from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { useModalUpdate } from "../../../context/ModalContext";

import OutsideClickHandler from "react-outside-click-handler";

const appearAnimation = keyframes(() => ({
  from: {
    opacity: "0",
  },
  to: {
    opacity: "1",
  },
}));

const PanelStyle = style((_theme, $) => ({
  position: "absolute",
  top: "40px",
  left: "50%",
  transform: "translate(-50%, 0)",
  backgroundColor: "rgba(255,255,255, 0.9)",
  borderRadius: "20px",
  display: "flex",
  width: "620px",
  height: "680px",
  flexDirection: "column",
  justifyContent: "center",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontSize: "15px",
  color: "#395B50",
  textAlign: "justify",
  padding: "20px",
  animation: $(appearAnimation) + " 1s",
  animationIterationCount: "1",

  [`@media (max-width: ${660}px)`]: {
    top: "30px",
    width: "520px",
    height: "520px",
    fontSize: "12px",
  },

  [`@media (max-width: ${530}px)`]: {
    top: "35px",
    width: "420px",
    fontSize: "12px",
  },

  [`@media (max-width: ${440}px)`]: {
    backgroundColor: "rgba(255,255,255, 0.98)",
    width: "330px",
    height: "580px",
    fontSize: "11px",
  },

  [`@media (max-width: ${370}px)`]: {
    width: "250px",
    fontSize: "10px",
  },
}));

const HeadingStyle = style(() => ({
  display: "flex",
  alignSelf: "center",
  textAlign: "center",
}));

const ButtonStyle = style(() => ({
  backgroundColor: "#2ECF95",
  borderRadius: "20px",
  borderColor: "transparent",
  width: "58px",
  height: "26px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  alignSelf: "center",
  [`@media (max-width: ${660}px)`]: {
    fontSize: "13px",
  },

  [`@media (max-width: ${530}px)`]: {
    fontSize: "12px",
  },

  [`@media (max-width: ${440}px)`]: {
    fontSize: "11px",
  },
}));

const RulesModal = () => {
  const panelStyle = useThemedStyle(PanelStyle);
  const headingStyle = useThemedStyle(HeadingStyle);
  const buttonStyle = useThemedStyle(ButtonStyle);
  const setOpen = useModalUpdate();

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen();
        }}
      >
        <div className={panelStyle}>
          <h1 className={headingStyle}>rules</h1>
          associations is a team game where players take turns explaining and
          guessing words. it has a preparation phase and 3 rounds:{" "}
          <p>
            preparation - each player writes 5 words (or more) on a sheet of
            paper, folds it and puts them in a bowl. then players team up in
            groups of 2 and sit opposite to one another in a circle made up of
            all players.{" "}
          </p>
          <p>
            round 1 - the first player (randomly chosen) has 1 minute (or more)
            to explain as many words as he can to his partner. he takes out
            words one by one from the bowl and can only describe the word with
            phrases that do not include derivatives of the word. Once the time
            limit has passed, the bowl is passed to the right and the timer is
            started anew. this continues until all words have been taken out
            from the bowl. then 1 point is awarded to teams for each guessed
            word and finally all words are folded and put back in the bowl.
          </p>
          <p>
            {" "}
            round 2 - the bowl starts from the player that was supposed to be
            next the previous round. the guessing process remains the same,
            however this time the player can only use ONE word to describe the
            word.
          </p>
          <p>
            round 3 - the bowl starts from the player that was supposed to be
            next the previous round. the guessing process remains the same,
            however this time the player can only use mimics to describe the
            words.
          </p>
          The team with the highest amount of points at the end of the game
          wins!
          <h1 className={headingStyle}>support</h1>
          <p className={headingStyle}>
            if you want to report a bug, please send us an e-mail at:
            <br /> associations-game@protonmail.com
          </p>
          <button onClick={() => setOpen()} className={buttonStyle}>
            OK
          </button>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default RulesModal;
