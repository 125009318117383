import React, { useContext, useCallback } from "react";
import GuessingRound from "./GuessingRound";
import { SocketContext } from "../../context/socket";
import Leaderboard from "./Leaderboard";
import WaitingRoom from "./WaitingRoom";
import WordSubmission from "./WordSubmission";

function Room(props) {
  const socket = useContext(SocketContext);

  const submitWaitingRoomHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit(
      "submitWaitingRoom",
      { roomCode: roomCode, username: username },
      function (response) {
        if (response === "false") {
          alert("Players are not pair or need 2 teams at least");
        }
      }
    ); // eslint-disable-next-line
  }, []);

  const submitWordSubmissionHandler = useCallback((words, username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("submitWordSubmission", {
      roomCode: roomCode,
      words: JSON.stringify(words),
      username: username,
    }); // eslint-disable-next-line
  }, []);

  const nextWordHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("nextWord", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const nextTeamHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("nextTeam", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const previousWordHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("previousWord", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const skipWordHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("skipWord", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const startTimerHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("startTimer", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const submitEndGameHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("endGame", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const submitNextRoundHandler = useCallback((username) => {
    let roomCode = localStorage.getItem("roomCode");
    socket.emit("nextRound", { roomCode: roomCode, username: username }); // eslint-disable-next-line
  }, []);

  const switchScreens = () => {
    switch (props.gameState.stage) {
      case "GuessingRound":
        return (
          <GuessingRound
            state={JSON.parse(props.gameState.state)}
            nextWord={nextWordHandler}
            previousWord={previousWordHandler}
            skipWord={skipWordHandler}
            nextTeam={nextTeamHandler}
            startTimer={startTimerHandler}
          />
        );

      case "WaitingRoom":
        return (
          <WaitingRoom
            state={JSON.parse(props.gameState.state)}
            submitEndGame={submitEndGameHandler}
            submitWaitingRoom={submitWaitingRoomHandler}
          />
        );
      case "WordSubmission":
        return (
          <WordSubmission
            state={JSON.parse(props.gameState.state)}
            submitEndGame={submitEndGameHandler}
            submitWordSubmission={submitWordSubmissionHandler}
          />
        );
      case "Leaderboard":
        return (
          <Leaderboard
            state={JSON.parse(props.gameState.state)}
            submitEndGame={submitEndGameHandler}
            submitNextRound={submitNextRoundHandler}
          />
        );
      default:
        return <p>Error</p>;
    }
  };

  return (
    <>
      <SocketContext.Provider value={socket}>
        {switchScreens()}
      </SocketContext.Provider>
    </>
  );
}
export default Room;
