import React, { useState } from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import Title from "../titles/Title";

const floatingAnimation = keyframes(() => ({
  "0%": {
    transform: "translatey(0px)",
  },
  "50%": {
    transform: "translatey(30px)",
  },
  "100%": {
    transform: "translatey(0px)",
  },
}));

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  marginTop: "80px",
}));

const InputStyle = style((theme) => ({
  borderRadius: "20px",
  border: 0,
  backgroundColor: "#e0e0e0",
  color: "#395B50",
  textAlign: "center",
  fontWeight: "bold",
  width: "290px",
  height: "40px",
  marginBottom: "10px",
  "&:focus": {
    outline: "none",
    //caretColor: "transparent",
  },
  "&::placeholder": {
    // color: "#f0f0f0",
    color: theme.placeholder,
    textAlign: "left",
  },
}));

const PlayerUnorderedListStyle = style(() => ({
  marginTop: "80px",
  marginBottom: "30px",
  maxWidth: "900px",
  fontFamily: "Neucha",
  fontSize: "26px",
  display: "flex",
  listStyle: "none",
  flex: "1 0 17%",
  flexWrap: "wrap",
  gap: "80px",
}));

const ActivePlayerStyle = style((theme, $) => ({
  color: theme.activePlayer,
  animation: $(floatingAnimation) + " 6s ease-in-out infinite",
}));

const ButtonPrimaryStyle = style((theme) => ({
  backgroundColor: theme.buttonPrimary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "40px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "80px",
}));

const ButtonSecondaryStyle = style((theme) => ({
  backgroundColor: theme.buttonDelete,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "10px",
}));

function WordSubmission(props) {
  const rootStyle = useThemedStyle(RootStyle);
  const inputStyle = useThemedStyle(InputStyle);
  const playerUnorderedListStyle = useThemedStyle(PlayerUnorderedListStyle);
  const activePlayerStyle = useThemedStyle(ActivePlayerStyle);
  const buttonPrimaryStyle = useThemedStyle(ButtonPrimaryStyle);
  const buttonSecondaryStyle = useThemedStyle(ButtonSecondaryStyle);

  let fields = new Array(parseInt(props.state.nbWords)).fill("");
  let username = localStorage.getItem("username");
  let buttonDelete = null;

  const [words, setWords] = useState([]);

  const onSubmit = () => {
    words.filter(Boolean);
    props.submitWordSubmission(words, username);
  };
  const onSubmitEndGame = () => {
    props.submitEndGame(username);
  };

  function setElement(e) {
    let copyWords = [...words];
    copyWords[e.target.name] = e.target.value;
    setWords(copyWords);
  }

  if (username === props.state.admin.username) {
    buttonDelete = (
      <button className={buttonSecondaryStyle} onClick={onSubmitEndGame}>
        DELETE ROOM
      </button>
    );
  }

  return (
    <>
      <Title>write your words</Title>

      <div className={rootStyle}>
        {props.state.readyPlayers.includes(username) ? (
          <>
            <ul className={playerUnorderedListStyle}>
              {props.state.readyPlayers.map(function (playerName, idx) {
                return (
                  <li key={idx}>
                    <div className={activePlayerStyle}>
                      <span>{playerName}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
            {buttonDelete}
          </>
        ) : (
          <>
            {fields.map(function (_field, idx) {
              return (
                <input
                  className={inputStyle}
                  name={idx}
                  key={idx}
                  type='text'
                  autoComplete='off'
                  maxLength='37'
                  placeholder={idx + 1}
                  onChange={setElement}
                  required
                />
              );
            })}
            <button className={buttonPrimaryStyle} onClick={onSubmit}>
              SUBMIT WORDS
            </button>

            {buttonDelete}
          </>
        )}
      </div>
    </>
  );
}

export default WordSubmission;
