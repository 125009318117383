import React from "react";
import { motion } from "framer-motion";

const WordSkippedAnimation = () => {
  return (
    <motion.h1
      animate={{
        rotateY: 360,
        color: "#FFD32D",
        fontFamily: "Fira Sans",
        fontSize: "48px",
        textAlign: "center",
      }}
    >
      skipped
    </motion.h1>
  );
};
export default WordSkippedAnimation;
