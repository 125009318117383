import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import TimerAnimation from "../animations/TimerAnimation";

const CurrentTeamNameStyle = style((theme) => ({
  fontFamily: "Neucha",
  fontSize: "48px",
  color: theme.activePlayer,
}));

const SpanNextTeamStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontWeight: "bold",
  letterSpacing: "0.16em",
  fontSize: "24px",
  color: theme.color,
}));

const NextTeamNameStyle = style(() => ({
  fontFamily: "Neucha",
  fontSize: "27px",
  color: "#BABABA",
}));

export const WaitingPlayerActiveTimer = (props) => {
  const currentTeamNameStyle = useThemedStyle(CurrentTeamNameStyle);
  const spanNextTeamStyle = useThemedStyle(SpanNextTeamStyle);
  const nextTeamNameStyle = useThemedStyle(NextTeamNameStyle);

  return (
    <>
      <CountdownCircleTimer
        isPlaying
        duration={props.state.countdown}
        colors={[["#C2D1CB", 1]]}
        strokeWidth={18}
      >
        {TimerAnimation}
      </CountdownCircleTimer>{" "}
      <p className={currentTeamNameStyle}>
        {props.state.explainingPlayer.username}
        {"\u00A0"} & {"\u00A0"}
        {props.state.guessingPlayer.username}
      </p>
      <p className={nextTeamNameStyle}>
        <span className={spanNextTeamStyle}>next team</span>
        <br />
        {props.state.nextTeam.name}
      </p>
    </>
  );
};
