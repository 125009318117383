import { global } from "themed-jss";

export const GlobalStyles = global((theme) => ({
  body: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Fira Sans', 'Neucha', sans-serif",
    fontSmooth: "antialiased",
    backgroundColor: theme.background,
    transition: "background 1s",
  },
  //html: { background: theme.background },
  code: {
    fontFamily:
      "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  },
  canvas: {
    display: "block",
    verticalAlign: "bottom",
  },
}));
