import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import SwitchButtonsCreateGame from "./SwitchButtonsCreateGame";

import { useSkips } from "../../../context/SkipsContext";

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  marginTop: "60px",
}));

const FormStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
}));

const ButtonPrimaryStyle = style((theme) => ({
  backgroundColor: theme.buttonPrimary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "40px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "60px",
}));

const ButtonTertiaryStyle = style((theme) => ({
  backgroundColor: theme.buttonTertiary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "10px",
}));

const CreateGameForm = (props) => {
  const rootStyle = useThemedStyle(RootStyle);
  const formStyle = useThemedStyle(FormStyle);
  const buttonPrimaryStyle = useThemedStyle(ButtonPrimaryStyle);
  const buttonTertiaryStyle = useThemedStyle(ButtonTertiaryStyle);

  const checkedSkips = useSkips();
  const validate = Yup.object({
    username: Yup.string()
      .max(15, "must be 15 characters or less")
      .required("required"),
    nbWords: Yup.number()
      .positive("must be a positive number")
      .integer("must be a number")
      .typeError("must be a number")
      .required("required"),
    timer: Yup.number()
      .positive("must be a positive number")
      .integer()
      .typeError("must be a number")
      .required("required"),
    nbSkips: Yup.number()
      .positive("must be a positive number")
      .integer()
      .typeError("must be a number"),
  });
  return (
    <Formik
      initialValues={{
        username: "",
        nbWords: "",
        timer: "",
        nbSkips: "",
        wordpackOptions: "",
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        props.createRoom({
          username: values.username,
          nbWords: values.nbWords,
          timer: values.timer,
          nbSkips: values.nbSkips,
          checkedSkips: checkedSkips,
          wordpackOptions: values.wordpackOptions,
        });
      }}
    >
      {() => (
        <div className={rootStyle}>
          <Form className={formStyle}>
            <TextField label='name' name='username' type='text' />
            <TextField
              label='number of words (player)'
              name='nbWords'
              type='tel'
            />
            <TextField label='timer (sec)' name='timer' type='tel' />
            {/* <WordpackDropdown /> */}

            <SwitchButtonsCreateGame />

            {checkedSkips && (
              <TextField label='skips (per team)' name='nbSkips' type='tel' />
            )}

            <button className={buttonPrimaryStyle} type='submit'>
              PLAY
            </button>
          </Form>

          <button
            className={buttonTertiaryStyle}
            type='submit'
            onClick={props.goBack}
          >
            BACK
          </button>
        </div>
      )}
    </Formik>
  );
};
export default CreateGameForm;
