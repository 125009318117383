import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { ErrorMessage, useField } from "formik";

const LabelStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  lineHeight: "38px",
  color: theme.color,
}));

const InputStyle = style(() => ({
  borderRadius: "20px",
  border: 0,
  backgroundColor: "#e0e0e0",
  color: "#395B50",
  textAlign: "center",
  fontWeight: "bold",
  width: "190px",
  height: "30px",
  "&:focus": {
    outline: "none",
    caretColor: "transparent",
  },
}));

const ErrorMessageStyle = style(() => ({
  color: "#ff6961",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Fira Sans",
}));

export const TextField = ({ label, ...props }) => {
  const inputStyle = useThemedStyle(InputStyle);
  const labelStyle = useThemedStyle(LabelStyle);
  const errorMessageStyle = useThemedStyle(ErrorMessageStyle);

  const [field] = useField(props);
  return (
    <>
      <label className={labelStyle} htmlFor={field.name}>
        {label}
      </label>
      <input className={inputStyle} {...field} {...props} autoComplete='off' />
      <ErrorMessage
        component='div'
        name={field.name}
        className={errorMessageStyle}
      />
    </>
  );
};
