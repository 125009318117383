import { React, useState } from "react";
import useSound from "use-sound";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import soundSfx from "../../../assets/rightAnswer.mp3";
import TimerAnimation from "../animations/TimerAnimation";

const WordContainerStyle = style((theme) => ({
  backgroundColor: "#F9F9F9",
  border: {
    width: 3,
    style: "solid",
    radius: "20px",
    color: theme.border,
  },
  minWidth: "190px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CurrentTeamNameStyle = style((theme) => ({
  fontFamily: "Neucha",
  fontSize: "48px",
  color: theme.activePlayer,
}));

const ButtonGuessedStyle = style((theme) => ({
  backgroundColor: theme.buttonPrimary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "40px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "10px",
  marginBottom: "50px",
}));

const ButtonBackStyle = style((theme) => ({
  backgroundColor: theme.buttonDelete,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "30px",
}));

const ButtonSkipStyle = style((theme) => ({
  backgroundColor: theme.buttonTertiary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#fff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "15px",
}));

const SkipsRemainingSpanStyle = style((theme) => ({
  color: theme.buttonTertiary,
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Fira Sans",
}));

export const ExplainingPlayerActiveTimer = (props) => {
  let username = localStorage.getItem("username");

  const wordContainerStyle = useThemedStyle(WordContainerStyle);
  const currentTeamNameStyle = useThemedStyle(CurrentTeamNameStyle);
  const buttonGuessedStyle = useThemedStyle(ButtonGuessedStyle);
  const buttonBackStyle = useThemedStyle(ButtonBackStyle);
  const buttonSkipStyle = useThemedStyle(ButtonSkipStyle);
  const skipsRemainingSpanStyle = useThemedStyle(SkipsRemainingSpanStyle);

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [play] = useSound(soundSfx);

  const buttonTimeout = () => {
    setButtonDisabled(true);
    const timer = setTimeout(() => setButtonDisabled(false), 1000);
    return () => clearTimeout(timer);
  };

  const onSubmitNext = () => {
    props.nextWord(username);
  };

  const onSubmitPrevious = () => {
    props.previousWord(username);
  };

  const onSubmitSkip = () => {
    props.skipWord(username);
  };

  return (
    <>
      <div className={wordContainerStyle}>
        <span>{props.state.currentRoundWord} </span>
      </div>
      <button
        disabled={isButtonDisabled}
        type='button'
        className={buttonGuessedStyle}
        onClick={() => {
          onSubmitNext();
          play();
          buttonTimeout();
        }}
      >
        GUESSED
      </button>
      <CountdownCircleTimer
        isPlaying
        duration={props.state.countdown}
        colors={[
          ["#395B50", 0.25],
          ["#05B560", 0.25],
          ["#DD6E42", 0.25],
          ["#820933", 0.25],
        ]}
        strokeWidth={18}
      >
        {TimerAnimation}
      </CountdownCircleTimer>
      <button
        disabled={isButtonDisabled}
        className={buttonBackStyle}
        onClick={() => {
          onSubmitPrevious();
          buttonTimeout();
        }}
      >
        BACK
      </button>

      {props.state.checkedSkips && props.state.skipsRemaining > 0 ? (
        <>
          <button
            disabled={isButtonDisabled}
            className={buttonSkipStyle}
            onClick={() => {
              onSubmitSkip();
              buttonTimeout();
            }}
          >
            SKIP
          </button>
          <span className={skipsRemainingSpanStyle}>
            remaining skips: {props.state.skipsRemaining}
          </span>
        </>
      ) : null}

      <p className={currentTeamNameStyle}>
        {props.state.explainingPlayer.username}
        {"\u00A0"} & {"\u00A0"}
        {props.state.guessingPlayer.username}
      </p>
    </>
  );
};
