import React from "react";
import { GuessingPlayerActiveTimer } from "./GuessingPlayerActiveTimer";
import { GuessingPlayerInactiveTimer } from "./GuessingPlayerInactiveTimer";

export const GuessingPlayerContent = (props) => {
  return !props.state.countdown ? (
    <GuessingPlayerInactiveTimer
      state={props.state}
      startTimer={props.startTimer}
    />
  ) : (
    <GuessingPlayerActiveTimer state={props.state} />
  );
};
