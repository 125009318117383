import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import Title from "../titles/Title";

const ButtonPrimaryStyle = style((theme) => ({
  backgroundColor: theme.buttonPrimary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "40px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "50px",
}));

const PlayerUnorderedListStyle = style((theme) => ({
  boxSizing: "border-box",
  borderWidth: 0,
  borderStyle: "solid",
  fontFamily: "Neucha",
  fontSize: "26px",
  color: theme.activePlayer,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const PlayerListItemStyle = style(() => ({
  display: "flex",
  marginTop: "10px",
  maxWidth: "900px",
  gap: "50px",
}));

function Leaderboard(props) {
  const buttonGuessedStyle = useThemedStyle(ButtonPrimaryStyle);
  const playerUnorderedListStyle = useThemedStyle(PlayerUnorderedListStyle);
  const playerListItemStyle = useThemedStyle(PlayerListItemStyle);
  let username = localStorage.getItem("username");
  let adminNextRoundButton = null;

  const onSubmitEndGame = () => {
    props.submitEndGame();
  };

  const onSubmitNextRound = () => {
    props.submitNextRound();
  };

  if (username === props.state.admin.username && props.state.final) {
    adminNextRoundButton = (
      <button className={buttonGuessedStyle} onClick={onSubmitEndGame}>
        end game
      </button>
    );
  } else if (username === props.state.admin.username && !props.state.final) {
    adminNextRoundButton = (
      <button className={buttonGuessedStyle} onClick={onSubmitNextRound}>
        {" "}
        next round{" "}
      </button>
    );
  }

  return (
    <>
      <Title>leaderboard</Title>
      <ul className={playerUnorderedListStyle}>
        {props.state.leaderboard
          .sort((a, b) => b[1] - a[1])
          .map((entry, idx) => {
            return (
              <li className={playerListItemStyle} key={idx}>
                <span>{entry[0]}</span>
                <span> {entry[1]}</span>
              </li>
            );
          })}{" "}
      </ul>

      <div>{adminNextRoundButton}</div>
    </>
  );
}
export default Leaderboard;
