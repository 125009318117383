import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  flexShrink: "0",
}));

const HeadingStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "4vmax",
  textAlign: "center",
  letterSpacing: "0.16em",
  color: theme.switchButton,
}));

const SubtitleStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "3vmax",
  textAlign: "center",
  letterSpacing: "0.16em",
  color: theme.color,
}));

export const TitleGuessingRound = ({ children }) => {
  const rootStyle = useThemedStyle(RootStyle);
  const headingStyle = useThemedStyle(HeadingStyle);
  const subtitleStyle = useThemedStyle(SubtitleStyle);

  return (
    <div className={rootStyle}>
      <h1 className={headingStyle}>
        {children[0]}
        <br />
        <span className={subtitleStyle}>{children[1]}</span>
      </h1>
    </div>
  );
};

export default TitleGuessingRound;
