import { React, useEffect, useState, useContext } from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import TitleGuessingRound from "../titles/TitleGuessingRound";
import WordGuessedAnimation from "./animations/WordGuessedAnimation";
import WordBackedAnimation from "./animations/WordBackedAnimation";
import WordSkippedAnimation from "./animations/WordSkippedAnimation";
import { SocketContext } from "../../context/socket";
import { ExplainingPlayerContent } from "./GuessingRoundContent/ExplainingPlayerContent";
import { GuessingPlayerContent } from "./GuessingRoundContent/GuessingPlayerContent";
import { WaitingPlayerContent } from "./GuessingRoundContent/WaitingPlayerContent";

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  marginTop: "60px",
}));

const ButtonNextTeamStyle = style((theme) => ({
  backgroundColor: theme.buttonDelete,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "10px",
}));

const FooterAdminStyle = style(() => ({
  marginTop: "60px",
}));

function GuessingRound(props) {
  let username = localStorage.getItem("username");
  let contentComponent;
  let buttonNextTeam = null;

  const rootStyle = useThemedStyle(RootStyle);
  const buttonNextTeamStyle = useThemedStyle(ButtonNextTeamStyle);
  const footerAdminStyle = useThemedStyle(FooterAdminStyle);
  const socket = useContext(SocketContext);
  const [guessed, setGuessed] = useState(false);
  const [backed, setBacked] = useState(false);
  const [skipped, setSkipped] = useState(false);

  const onSubmitTeam = () => {
    props.nextTeam(username);
  };

  const getText = () => {
    switch (props.state.round) {
      case "Round1":
        return ["round 1", "explain it!"];
      case "Round2":
        return ["round 2", "one word only!"];
      case "Round3":
        return ["round 3", "mimic it!"];
      default:
        <p>Guessing round????</p>;
    }
  };

  useEffect(() => {
    socket.on("wordGuessed", () => {
      setGuessed(true);
      setTimeout(() => {
        // After 1 second set the show value to false
        setGuessed(false);
      }, 500);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("wordBacked", () => {
      setBacked(true);
      setTimeout(() => {
        // After 1 second set the show value to false
        setBacked(false);
      }, 500);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("wordSkipped", () => {
      setSkipped(true);
      setTimeout(() => {
        // After 1 second set the show value to false
        setSkipped(false);
      }, 500);
    });
  }, [socket]);

  if (username === props.state.explainingPlayer.username) {
    contentComponent = (
      <ExplainingPlayerContent
        state={props.state}
        nextWord={props.nextWord}
        previousWord={props.previousWord}
        skipWord={props.skipWord}
      />
    );
  } else if (username === props.state.guessingPlayer.username) {
    contentComponent = (
      <GuessingPlayerContent
        state={props.state}
        startTimer={props.startTimer}
      />
    );
  } else {
    contentComponent = <WaitingPlayerContent state={props.state} />;
  }

  if (username === props.state.admin.username && !props.state.countdown) {
    buttonNextTeam = (
      <>
        <div>
          <button className={buttonNextTeamStyle} onClick={onSubmitTeam}>
            {" "}
            NEXT TEAM{" "}
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <TitleGuessingRound>{getText()}</TitleGuessingRound>

      <div className={rootStyle}>{contentComponent}</div>

      <div className={footerAdminStyle}>
        {buttonNextTeam}
        {guessed ? <WordGuessedAnimation /> : null}
        {backed ? <WordBackedAnimation /> : null}
        {skipped ? <WordSkippedAnimation /> : null}
      </div>
    </>
  );
}
export default GuessingRound;
