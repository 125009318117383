import React from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";

const darkModeAnimation = keyframes((theme) => ({
  from: {
    background: theme.text,
  },
  to: {
    background: "transparent",
  },
}));

const typingAnimation = keyframes(() => ({
  from: {
    width: 0,
  },
  to: {
    width: "100%",
  },
}));

const blinkingAnimation = keyframes((theme) => ({
  "50%": {
    borderColor: "transparent",
  },
}));

const TitleStyle = style((theme, $) => ({
  maxWidth: "597px",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "4vmax",
  textAlign: "center",
  letterSpacing: "0.16em",
  color: theme.color,
  overflow: "hidden",
  borderRight: ".15em solid",
  borderColor: "white",
  whiteSpace: "nowrap",
  animation: [
    $(blinkingAnimation) + " 1s step-end infinite",
    $(typingAnimation) + " 3.5s steps(60,end)",
    $(darkModeAnimation) + " 1s infinite",
  ],
  [`@media (max-width: ${570}px)`]: {
    maxWidth: "240px",
  },
}));

const Title = ({ children }) => {
  const titleStyle = useThemedStyle(TitleStyle);
  return <h1 className={titleStyle}>{children}</h1>;
};

export default Title;
