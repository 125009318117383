import React from "react";
import { WaitingPlayerActiveTimer } from "./WaitingPlayerActiveTimer";
import { WaitingPlayerInactiveTimer } from "./WaitingPlayerInactiveTimer";

export const WaitingPlayerContent = (props) => {
  return !props.state.countdown ? (
    <WaitingPlayerInactiveTimer state={props.state} />
  ) : (
    <WaitingPlayerActiveTimer state={props.state} />
  );
};
