import React from "react";
import ReactLoading from "react-loading";
import { createUseStyles } from "react-jss";

function NoConnection() {
  const useStyles = createUseStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      marginTop: "12%",
    },
  });
  const classes = useStyles();

  return (
    <>
      <ReactLoading
        type='spin'
        color='#FFD32D'
        height={"20%"}
        width={"20%"}
        className={classes.content}
      />
      <p>
        The game has been deactivated to save resources. Please send an e-mail
        to kaloyanker@gmail.com, if you want to play. Replying quickly!
      </p>
    </>
  );
}
export default NoConnection;
