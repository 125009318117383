import React from "react";
import { style, keyframes } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const pulseAnimation = keyframes(() => ({
  "0%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
}));

const CurrentTeamNameStyle = style((theme) => ({
  fontFamily: "Neucha",
  fontSize: "48px",
  color: theme.activePlayer,
}));

const SpanStartTimerStyle = style((theme, $) => ({
  backgroundColor: "transparent",
  color: theme.spanNextTeam,
  fontSize: "12px",
  border: 0,
  fontFamily: "Fira Sans",
  fontWeight: "bold",
  animation: $(pulseAnimation) + " 4s infinite both",
}));

export const ExplainingPlayerInactiveTimer = (props) => {
  const currentTeamNameStyle = useThemedStyle(CurrentTeamNameStyle);
  const spanStartTimerStyle = useThemedStyle(SpanStartTimerStyle);

  return (
    <>
      <p className={currentTeamNameStyle}>
        {props.state.explainingPlayer.username}
        {"\u00A0"} & {"\u00A0"}
        {props.state.guessingPlayer.username}
      </p>
      <CountdownCircleTimer
        isPlaying={false}
        duration={props.state.countdown}
        colors={[["#C2D1CB", 1]]}
        strokeWidth={18}
      >
        <span className={spanStartTimerStyle}>
          {props.state.guessingPlayer.username}
          <br /> should start the timer!
        </span>
      </CountdownCircleTimer>{" "}
    </>
  );
};
