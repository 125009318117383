import { useContext, useState, createContext } from "react";

const SkipsContext = createContext();
const SkipsUpdateContext = createContext();

export function useSkips() {
  return useContext(SkipsContext);
}

export function useSkipsUpdate() {
  return useContext(SkipsUpdateContext);
}

export function SkipsProvider({ children }) {
  const [checkedSkips, setCheckedSkips] = useState(false);

  const toggleSkips = (nextChecked) => {
    setCheckedSkips(nextChecked);
  };

  return (
    <SkipsContext.Provider value={checkedSkips}>
      <SkipsUpdateContext.Provider value={toggleSkips}>
        {children}
      </SkipsUpdateContext.Provider>
    </SkipsContext.Provider>
  );
}
