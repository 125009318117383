import React from "react";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import Switch from "react-switch";
import { useSkips, useSkipsUpdate } from "../../../context/SkipsContext";

const RootStyle = style(() => ({
  marginTop: "20px",
  marginBottom: "20px",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  gap: "20px",
  fontWeight: "bold",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
}));

const LabelStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  lineHeight: "38px",
  color: theme.color,
  display: "inline-flex",
  alignItems: "center",
}));

const SwitchButtonsCreateGame = () => {
  const rootStyle = useThemedStyle(RootStyle);
  const labelStyle = useThemedStyle(LabelStyle);
  const checkedSkips = useSkips();
  const handleChangeSkipsOption = useSkipsUpdate();

  return (
    <div className={rootStyle}>
      <label className={labelStyle}>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;no skips&nbsp;&nbsp;</span>
        <Switch
          onChange={handleChangeSkipsOption}
          onColor='#EE9746'
          offColor='#EE9746'
          handleDiameter={16}
          height={28}
          width={55}
          checked={checkedSkips}
          uncheckedIcon={null}
          checkedIcon={null}
        />
        <span>&nbsp;&nbsp;with skips</span>
      </label>
    </div>
  );
};

export default SwitchButtonsCreateGame;
